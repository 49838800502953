require('./index.scss');
require('./resources.js');

const $ = (window.jQuery = require('jquery'));
require('slick-carousel');
const AOS = require('aos');

function serviceMenu(sel){
	$(sel).click(function(){
		if ($(this).hasClass('active')) {
			$(this).find('ul').slideUp();
		} else {
			$(this).find('ul').slideDown();
		}
		$(this).toggleClass('active');
	});
}

function hideLabels(sel) {
	$(sel).focus(function () {
		$(this).parent().find('label').fadeOut();
	});

	$(sel).blur(function () {
		if (!$(this).val()) {
			$(this).parent().find('label').fadeIn();
		}
	});
}

function gaTriggers() {
	gtag('event', 'click', {
		event_category: $(this).data('ga-category'),
		event_label: $(this).data('ga-label'),
	});
}

function ajaxForm(sel) {
	$(sel).submit(function (e) {
		const me = this;
		const $me = $(me);
		const url = $me.attr('action');
		const action = me.dataset.action;
		e.preventDefault();

		const data = new FormData(me);
		grecaptcha.ready(function () {
			grecaptcha
				.execute('6LeB2roZAAAAAGwP7AkwVIfG5d3yv7prZVlB50jh', { action })
				.then(function (token) {
					data.set('recaptcha', token);

					$me.find('input, textarea').parent().removeClass('error');
					$.ajax({
						url,
						type: 'post',
						data,
						processData: false,
						contentType: false,
						success: function (resp) {
							if (resp.success) {
								if (window.gtag) {
									window.gtag('event', 'submit', {
										event_category: 'Form',
										event_label: url,
									});
								}
								me.reset();
								$me.find('.alert').addClass('active').html(resp.message);
								return;
							}

							for (var i = resp.errors.length - 1; i >= 0; i--) {
								$me.find(
									'[name=' + resp.errors[i] + ']'
								).parent().addClass('error');
							}
						},
					});
				});
		});
	});
}

$(function (){

	$('.introduction-screen').addClass('loading');
	setTimeout(function(){
		$('.introduction-screen.loading').addClass('loaded');
	}, 2000);


	$('.testimonial-container .testimonials').slick({
		dots: true,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 6000,
	});

	$('.js-togglemenu').click(function(){
		$('.menu_overlay').toggleClass('active');
		$('body').toggleClass('disabled');
	});

	$('.js-togglemenu-terms').click(function(){
		$('.menu_overlay').removeClass('active');
		$('body').removeClass('disabled');
	});

	$('.pgi_slider').slick({
		asNavFor: '.slider_content',
		infinite: true,
		swipeToSlide: true,
		arrows: false,
		autoplay: true,
		dots: true,
		autoplaySpeed: 5000,
		speed: 500,
		pauseOnHover: false,
	});

	$('.slider_content').slick({
		asNavFor: '.pgi_slider',
		infinite: true,
		arrows: false,
		focusOnSelect: true,
		autoplaySpeed: 5000,
		speed: 500,
		pauseOnHover: false,
	});

	$('.js-track').click(gaTriggers);
	ajaxForm('form.ajax');
	serviceMenu('.services-menu');
	hideLabels('.js-hide-label')

	AOS.init({
		duration: 1200,
	})

	var noHashURL = window.location.href.replace(/#.*$/, '');
	window.history.replaceState('', document.title, noHashURL);
	

});
