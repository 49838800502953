require('./image/favicon/android-chrome-192x192.png');
require('./image/favicon/android-chrome-384x384.png');
require('./image/favicon/apple-touch-icon.png');
require('./image/favicon/favicon-16x16.png');
require('./image/favicon/favicon-32x32.png');
require('./image/favicon/mstile-150x150.png');
require('./image/favicon/safari-pinned-tab.svg');
require('./image/favicon/site.webmanifest');

require('./image/pgi-colour.svg');
require('./image/pgi-white.svg');

require('./image/logo-loading.svg');

require('./image/icons/arrow-grey.svg');
require('./image/icons/arrow-orange.svg');
require('./image/icons/arrow-white.svg');
require('./image/icons/close-icon.svg');
require('./image/icons/white-line.svg');
require('./image/icons/menu-icon.svg');
require('./image/icons/slider-right-arrow.svg');
require('./image/icons/slider-left-arrow.svg');

require('./image/about.png');
require('./image/about-right.png');
require('./image/about-banner.png');
require('./image/about-full.png');

require('./image/call-us.png');
require('./image/logo.png');
require('./image/pgi_slider.png');
require('./image/pgi_slider_2.png');
require('./image/services.png');

require('./image/retirement-banner.png');
require('./image/retirement-full.png');
require('./image/retirement-contact.png');

require('./image/investment-banner.png');
require('./image/investment-full.png');
require('./image/investment-contact.png');

require('./image/estate-banner.png');
require('./image/estate-full.png');
require('./image/estate-contact.png');

require('./image/values-banner.png')
require('./image/values-placeholder.png')

require('./image/testimonials-banner.png');

require('./image/what-we-do-banner.png');
require('./image/retirement-service.png');
require('./image/investment-service.png');
require('./image/estate-service.png');

require('./image/contact-banner.png');

require('./image/privacy-banner.png');

require('./image/notfound.png');

require('./image/values-1.png');
require('./image/values-2.png');
require('./image/values-3.png');
require('./image/values-4.png');
